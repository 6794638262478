<template>
  <div class="atomic-search">
    <atomic-icon id="search" />
    <input
      class="atomic-search__input"
      type="text"
      :placeholder="getContent(layoutData, defaultLocaleLayoutData, 'header.searchPlaceholder')"
    />
  </div>
</template>

<script setup lang="ts">
  const { getContent } = useProjectMethods();
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
</script>

<style src="~/assets/styles/components/atomic/search.scss" lang="scss" />
