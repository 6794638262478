<template>
  <client-only>
    <div class="atomic-deposit">
      <div class="atomic-deposit__balance">
        {{ formatAmount(activeAccount?.currency, activeAccount?.balance) }}
      </div>
      <button-cashier />
    </div>
  </client-only>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const walletStore = useWalletStore();
  const { formatAmount } = useProjectMethods();
  const { activeAccount } = storeToRefs(walletStore);
</script>

<style src="~/assets/styles/components/atomic/deposit.scss" lang="scss" />
