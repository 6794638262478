<template>
  <div class="cookies" @click="handleClick">
    <atomic-icon id="cookies" class="cookies__icon" />
    <div v-if="descriptionContent" class="cookies__description">
      {{ descriptionContent }}
    </div>
    <div class="cookies__close" @click.stop="acceptCookie">
      <atomic-icon id="close" class="cookies__icon" />
    </div>
  </div>
</template>

<script setup lang="ts">
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const layoutStore = useLayoutStore();
  const { localizePath } = useProjectMethods();
  const router = useRouter();
  const acceptCookie = (): void => {
    const userCookie = useCookie('accept-cookie', { maxAge: 60 * 60 * 24 * 365 * 10 });
    userCookie.value = 'accepted';
    layoutStore.showCookiePopup = false;
  };
  const descriptionContent = computed(() => layoutData?.cookie?.text || defaultLocaleLayoutData?.cookie?.text);

  const url = computed(() => layoutData?.cookie?.url || defaultLocaleLayoutData?.cookie?.url);

  const handleClick = () => {
    if (url.value) router.push(localizePath(url.value));
  };
</script>

<style src="~/assets/styles/components/layout/cookies.scss" lang="scss" />
