<template>
  <div class="toggle-drawer">
    <div class="toggle-drawer__btn" @click="emit('toggle-minimize')" :class="{'is-active': props.isActive }">
      <div class="toggle-drawer__btn-overlay-hover"/>
      <div class="toggle-drawer__btn-border-overlay"/>
      <div class="toggle-drawer__btn-overlay"/>
      <atomic-icon :id=" props.isActive ? 'close' : 'menu'" />
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    isActive: boolean;
  }>()
  const emit = defineEmits(['toggle-minimize']);
</script>

<style src="~/assets/styles/components/button/toggle-drawer.scss" lang="scss" />
