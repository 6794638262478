<template>
  <button-base class="btn-bonuses" type="secondary-2" url="/bonuses">
    <atomic-icon id="gift" />
    <span v-if="count" class="btn-bonuses__count">{{ count }}</span>
  </button-base>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const bonusStore = useBonusStore();
  const { bonusesCount } = storeToRefs(bonusStore);

  const count = computed(() => (bonusesCount.value > 99 ? '99+' : bonusesCount.value));
</script>

<style src="~/assets/styles/components/button/bonuses.scss" lang="scss" />
