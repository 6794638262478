<template>
  <div v-if="!isGamePage" class="nav-mob">
    <nuxt-link class="nav-mob__item" :to="localizePath('/')" :class="{ 'is-active': route.path === localizePath('/') }">
      <atomic-svg :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.home.icon')" />
      <span class="nav-mob__text">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.home.label') }}
      </span>
    </nuxt-link>

    <div class="nav-mob__item" :class="{ 'is-active': showSearch }" @click="showSearch = !showSearch">
      <atomic-svg :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.search.icon')" />
      <span class="nav-mob__text">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.search.label') }}
      </span>
    </div>

    <div class="nav-mob__item" @click.prevent="clickMainButton">
      <atomic-svg :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.cashier.icon')" />
      <span class="nav-mob__text">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.cashier.label') }}
      </span>
    </div>

    <nuxt-link
      class="nav-mob__item"
      :to="localizePath('/bonuses')"
      :class="{ 'is-active': route.path === localizePath('/bonuses') }"
    >
      <span v-if="bonusCount" class="bonus-count">{{ bonusCount }}</span>
      <atomic-svg :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.bonuses.icon')" />
      <span class="nav-mob__text">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.bonuses.label') }}
      </span>
    </nuxt-link>

    <div class="nav-mob__item" @click.prevent="layoutStore.toggleDrawer()">
      <atomic-svg :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.menu.icon')" />
      <span class="nav-mob__text">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.menu.label') }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const layoutStore = useLayoutStore();
  const profileStore = useProfileStore();
  const { showSearch } = storeToRefs(layoutStore);
  const { isLoggedIn } = storeToRefs(profileStore);
  const { openModal, openWalletModal } = useModalStore();
  const { isGamePage } = storeToRefs(layoutStore);
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { localizePath, getContent } = useProjectMethods();
  const route = useRoute();

  const bonusStore = useBonusStore();
  const { bonusesCount } = storeToRefs(bonusStore);

  const clickMainButton = (): void => {
    if (isLoggedIn.value) openWalletModal();
    else openModal('sign-in');
  };

  const bonusCount = computed(() => (bonusesCount.value > 99 ? '99+' : bonusesCount.value));
</script>

<style src="~/assets/styles/components/nav/mob.scss" lang="scss" />
