<template>
  <div class="drawer" :class="{ 'is-compact': isDrawerCompact }">
    <div class="header">
      <client-only>
        <atomic-link class="drawer__logo" href="/">
          <atomic-image class="img" src="/img/logo-short.svg" />
        </atomic-link>
      </client-only>

      <div class="drawer__close">
        <atomic-icon id="close" />
      </div>
    </div>

    <div class="content">
      <client-only>
        <atomic-link v-if="isLoggedIn && loyaltyEnabled" href="/loyalty" class="drawer__loyalty">
          <loyalty-level-logo />
          <loyalty-progress short-version />
        </atomic-link>
      </client-only>

      <nav-list :items="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.categoriesMenu') || []" />
      <atomic-divider />

      <nav-list :items="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.mainMenu') || []" />

      <div class="drawer__footer-menu">
        <atomic-select-lang />

        <div class="nav-list">
          <div class="item">
            <div class="link" @click="openChat">
              <atomic-icon id="chat-support" />
              <div class="text">
                {{ getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.footerMenu.chatLabel') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { getContent } = useProjectMethods();

  const layoutStore = useLayoutStore();
  const { isDrawerCompact } = storeToRefs(layoutStore);

  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);

  const {
    public: { loyaltyEnabled },
  } = useRuntimeConfig();

  const openChat = async (): Promise<void> => {
    window.zE('webWidget', 'show');
    await nextTick();
    window.zE('webWidget', 'open');
  };
</script>

<style src="~/assets/styles/components/layout/drawer.scss" lang="scss" />
